import { openSans } from '../fonts/fonts';

const themes = {
  /* Theaming System */
  biciva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-biciva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },

  futbolva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-futbolva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  kidsva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-kidsva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },

  outdoorva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-outdoorva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  runningva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-runningva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  tenisva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-tenisva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  trainingva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-trainingva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  urbanva: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-urbanva)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  vaypol: {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-grey)',
    'color-footer-bg': 'var(--color-vaypolva)',
    'color-footer-text': 'var(--color-black)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  '+sports': {
    'bg-newsletter': '#d9d9d9',
    'color-text': 'var(--color-white)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-grey)',
    'color-primary': 'var(--color-vaypolva)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-vaypolva)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-vaypolva)',
    'color-text-on-secondary': 'var(--color-vaypolva)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-vaypolva)',
    'color-disabled': 'var(--color-vaypolva)',
    'color-text-on-disabled': 'var(--color-vaypolva)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-grey)',
    'color-footer-bg': 'var(--color-black)',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-red-offer)',
    'color-favorite': 'var(--color-favorite-fully-vaypol)'
  },
  city: {
    'font-family': openSans.style.fontFamily,
    'color-text': 'var(--color-white)',
    'bg-newsletter': 'linear-gradient(to bottom, #4AC7DD, #207F8F, #2A2A2A)',
    'color-background': 'var(--color-black)',
    'color-background-80': 'var(--color-black-70)',
    'color-background-container': 'var(--color-black)',
    'color-primary': 'var(--color-city-primary)',
    'color-text-on-primary': 'var(--color-grey)',
    'color-primary-alternative': 'var(--color-white-absolute)',
    'color-text-on-primary-alternative': 'var(--color-grey)',
    'color-secondary': 'var(--color-city-secondary)',
    'color-text-on-secondary': 'var(--color-city)',
    'color-terciary': 'var(--color-grey)',
    'color-text-on-terciary': 'var(--color-grey)',
    'color-text-on-transparent': 'var(--color-white-absolute)',
    'color-text-on-transparent-disabled': 'var(--color-grey-light)',
    'color-link-text': 'var(--color-city)',
    'color-disabled': 'var(--color-city)',
    'color-text-on-disabled': 'var(--color-city)',
    'color-border-neutral': 'var(--color-grey-light)',
    'color-navbar': 'var(--color-grey)',
    'color-footer-bg': '#2A2A2A',
    'color-footer-text': 'var(--color-white-absolute)',
    'color-discount': 'var(--color-city-secondary)',
    'color-favorite': 'var(--color-favorite-fully-city)'
  }
};

export default themes;
