'use client';

/* eslint-disable */

import '@/styles/main.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import themes from '@/styles/themes';
import { usePathname } from 'next/navigation';
import { setPageLoading } from '@/redux/ssrInfo/ssrInfoReducer';
import { PageSpinner } from '@/components/molecules';

const ThemeProvider = ({ children }) => {
  const theme = useSelector((state) => state.store);
  const pathname = usePathname();
  const { loading } = useSelector((state) => state.ssrInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      dispatch(setPageLoading(false));
    }
  }, [pathname]);

  useEffect(() => {
    const newTheme = themes[theme] || themes[process.env.NEXT_PUBLIC_STORE_ID];
    Object.keys(newTheme)?.forEach((key) => {
      const cssKey = `--${key}`;
      const cssValue = newTheme[key];
      document.body.style.setProperty(cssKey, cssValue);
    });
  }, [theme]);

  return (
    <>
      {children}
      {loading && <PageSpinner />}
    </>
  );
};

export default ThemeProvider;
