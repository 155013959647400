'use client';

import { usePathname } from 'next/navigation';
import { Montserrat } from 'next/font/google';
import StoreProvider from '@/redux/appRouter/Provider';
import ThemeProvider from '@/components/organisms/ThemeProvider';
import AsistantChat from '@/components/molecules/AsistantChat';
import ChangeModalCart from '@/components/molecules/ChangeCartModal';
import EventTracking from '@/components/organisms/EventTracking';

const montserrat = Montserrat({
  weight: ['100', '300', '400', '600', '700', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap'
});

const FACEBOOK_VERIFICATION_ID =
  process.env.NEXT_PUBLIC_FACEBOOK_VERIFICATION_ID;
const GOOGLE_VERIFICATION_ID = process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_ID;
const allowRobots = process.env.NEXT_PUBLIC_ALLOW_ROBOTS;

// eslint-disable-next-line react/prop-types

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

// eslint-disable-next-line react/prop-types
const RootLayout = ({ children }) => {
  const pathname = usePathname();

  return (
    <html lang="es">
      <head>
        <link
          rel="preconnect"
          href="https://s3.sa-east-1.amazonaws.com"
        />
        <link
          rel="icon"
          href={`/favicon-${storeId}.ico`}
        />
        {allowRobots === 'false' && (
          <meta
            name="robots"
            content="noindex,nofollow"
          />
        )}
        <meta
          name="version"
          content={process.env.NEXT_PUBLIC_APP_VERSION}
        />
        {FACEBOOK_VERIFICATION_ID && (
          <meta
            name="facebook-domain-verification"
            content={FACEBOOK_VERIFICATION_ID}
          />
        )}
        {GOOGLE_VERIFICATION_ID && (
          <meta
            name="google-site-verification"
            content={GOOGLE_VERIFICATION_ID}
          />
        )}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </head>
      <body className={montserrat.className}>
        <StoreProvider>
          <EventTracking>
            <ThemeProvider>
              {children}
              <ChangeModalCart />
              {!pathname.includes('checkout') && <AsistantChat />}
              <div id="root-toastify" />
              <div id="portal-root" />
            </ThemeProvider>
          </EventTracking>
        </StoreProvider>
      </body>
    </html>
  );
};

export default RootLayout;
