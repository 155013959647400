'use client';

/* eslint-disable no-use-before-define */
import { useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import useTrackingQueue from '../useTrackingQueue';

// hook para enviar un evento pageview cuando se produce un cambio de pagina
const usePageView = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { executeEvent, trackingReady } = useTrackingQueue();

  // Al iniciar la web, iniciamos un evento de pageview.
  // Como la lib de pixel/analytics todavia no se cargan,
  // el evento se va a guardar en cola para ejecutarse luego
  useEffect(() => {
    executeEvent('pageview', pathname);
  }, []);

  // al cambiar de pagina ejecutamos el evento pageview
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // usamos como dependencia executeEvent
    // para poder tener una referencia actualizada por si llega a cambiar trackingReady
  }, [router.events, executeEvent, trackingReady]);

  const handleRouteChange = (url) => {
    const wCentrixDom = document.getElementById('wcx-chat');
    if (wCentrixDom) {
      if (url.includes('checkout')) {
        wCentrixDom.style.display = 'none';
      } else {
        wCentrixDom.style.display = 'block';
      }
    }

    executeEvent('pageview', url);
  };
};

export default usePageView;
