import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const helpSlice = createSlice({
  name: 'helps',
  initialState,
  reducers: {
    setHelps(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});

export const { setHelps } = helpSlice.actions;
export default helpSlice.reducer;
