import { MSG_SERVER_ERROR, MSG_CLIENT_ERROR } from './messages';

// descargamos la lib de toastify solo cuando haga falta
const toastify = () => import(/* webpackChunkName: "toastify" */ '../toastify');

// handlear errores de llamdas a la API
export default function handler(err, noSendToast = false) {
  let message = null;
  let errorsList = [];

  // client received response
  if (err.response) {
    const { errors } = err.response.data;

    if (errors) {
      const generalError = errors.find((x) => x.error_type === 'general');

      if (generalError) {
        message = generalError.error_description || MSG_CLIENT_ERROR;
      } else {
        message = errors[0].error_description || MSG_CLIENT_ERROR;
      }
      // TODO revisar en que momento se utiliza un arreglo de errores (errorsList)
      errorsList = errors;
    } else {
      message = `ERROR: ${err.response.config.url}: ${JSON.stringify(
        err.response.data
      )}`;
    }
  } else if (err.request) {
    // client never received a response, or request never left
    message = MSG_SERVER_ERROR;
  } else {
    // anything else
    message = MSG_CLIENT_ERROR;
  }

  if (message && !noSendToast && typeof window !== 'undefined') {
    // mostramos el mensaje en un toast
    toastify().then((toast) => toast.error(message));
  }

  return errorsList.length > 0 ? errorsList : err;
}
