/* eslint-disable import/no-mutable-exports */
import { useMemo } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/nextjs';
import faqsReducer from '@/redux/faqs/faqsReducer';
import helpReducer from '@/redux/help/helpReducer';
import bannersReducer from '@/redux/banners/bannersReducer';
import storebranchesReducer from '@/redux/storebranches/storebranchesReducer';
import mapReducer from '@/redux/map/mapReducer';
import checkoutReducer from '@/redux/checkout/checkoutReducer';
import cartReducer from '@/redux/cart/cartReducer';
import productsReducer from '@/redux/products/productsReducer';
import productDetailReducer from '@/redux/productDetail/productDetailReducer';
import navBarReducer from '@/redux/navBar/navBarReducer';
import trackingReducer from '@/redux/tracking/trackingReducer';
import storeReducer from '@/redux/store/storeReducer';
import newsletterReducer from '@/redux/newsletter/newsletterReducer';
import streamingReducer from '@/redux/streaming';
import machineInformationReducer from '@/redux/machineInformation/machineInformationReducer';
import ssrInfoReducer from '@/redux/ssrInfo/ssrInfoReducer';

const sentryReduxEnhancer = createReduxEnhancer({
  actionTransformer: (action) => (action.type === '@@INIT' ? null : action)
});

let store;

export const reducer = {
  ssrInfo: ssrInfoReducer,
  faqs: faqsReducer,
  help: helpReducer,
  banners: bannersReducer,
  navBar: navBarReducer,
  map: mapReducer,
  cart: cartReducer,
  storebranches: storebranchesReducer,
  checkout: checkoutReducer,
  products: productsReducer,
  productDetail: productDetailReducer,
  tracking: trackingReducer,
  store: storeReducer,
  newsletter: newsletterReducer,
  streaming: streamingReducer,
  machineInformation: machineInformationReducer
};

export default function initStore(initialState = {}) {
  return configureStore({
    reducer,
    preloadedState: initialState,
    enhancers: [sentryReduxEnhancer]
  });
}

export function initializeStore(preloadedState) {
  let _store = store ?? initStore(preloadedState);
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
      faqs: { ...store.getState().faqs, ...preloadedState.faqs }
    });

    // Reset the current store
    store = undefined;
  }

  if (typeof window === 'undefined') return _store;
  if (!store) store = _store;

  return _store;
}

export function useStore(initialState) {
  const _store = useMemo(() => {
    return initializeStore(initialState);
  }, [initialState]);
  return _store;
}

export { store };
