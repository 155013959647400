import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState,
  reducers: {
    setNewsletter(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});

export const { setNewsletter } = newsletterSlice.actions;
export default newsletterSlice.reducer;
