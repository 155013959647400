import { createSlice } from '@reduxjs/toolkit';
import { runQueue } from '../../services/queue';

const initialState = {
  ready: false,
  pixel: false,
  analytics: false
};

const tracking = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    setTrackingReady(state, action) {
      state[action.payload] = true;
      state.ready = true;
      if (window.gtag && window.fbq) {
        runQueue();
      }
    },
    setTracking(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});
export const { setTrackingReady, setTracking } = tracking.actions;
export default tracking.reducer;
