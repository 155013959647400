import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const storeBranchesSlice = createSlice({
  name: 'storeBranches',
  initialState,
  reducers: {
    setStoreBranches(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});

export const { setStoreBranches } = storeBranchesSlice.actions;
export default storeBranchesSlice.reducer;
