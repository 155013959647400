'use client';

import { useEffect } from 'react';

// hook para descargar la lib de chat wcentrix
// se aplica un retardo de X segundos para evitar cargarlo al inicio de la app
export default function useWcentrixChat() {
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_MAINTENANCE !== 'true' &&
      process.env.NEXT_PUBLIC_MODE_ENV === 'production' &&
      process.env.NEXT_PUBLIC_WEBCENTRIX_ID
    ) {
      setTimeout(() => {
        const url = `https://api.wcx.cloud/widget/?id=${process.env.NEXT_PUBLIC_WEBCENTRIX_ID}`;
        const s = document.createElement('script');
        s.setAttribute('src', url);

        document.body.appendChild(s);

        // Se selecciona el nodo padre del div a observar
        const parentNode = document.body;

        // Se configura el observador para observar cambios en el nodo padre
        const observerConfig = {
          childList: true,
          subtree: false
        };

        const observer = new MutationObserver((mutations) => {
          setTimeout(() => {
            mutations?.forEach(() => {
              // Comprobación si se agrego wcentrix en el DOM
              const wcentrix = document.getElementById('wcx-chat');
              if (wcentrix?.children.length > 0) {
                wcentrix.style.display = 'initial';
                const button = wcentrix.querySelector('button');
                button.setAttribute('aria-label', 'Chat de asistencia');
                button.style.background = 'var(--color-primary)';

                observer.disconnect();
              } else if (wcentrix) wcentrix.style.display = 'none';
            });
          }, 0);
        });

        // Iniciar la observación
        observer.observe(parentNode, observerConfig);

        return () => {
          observer.disconnect();
        };
      }, 3500);
    }
  }, []);
}
