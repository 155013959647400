import { createSlice } from '@reduxjs/toolkit';

const initialState = process.env.NEXT_PUBLIC_STORE_ID;

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore(_state, action) {
      return action.payload || _state;
    }
  }
});

export const { setStore } = storeSlice.actions;
export default storeSlice.reducer;
