'use client';

import { useSelector } from 'react-redux';
import { runEvent } from '../../services/queue';

// hook encargado de verificar si analytics y pixel estan cargados
// y ejecutar el evento deseado, o guardarlos en una cola para ejecutarlos luego
const useTrackingQueue = () => {
  const trackingReady = useSelector((state) => state.tracking.ready);

  // funcion que recibe el evento y los params del mismo
  // y se verifica si se tiene que agregar a la cola
  const executeEvent = (name, options) => {
    runEvent(name, options, trackingReady);
  };

  // el hook devuelve la funcion y el estado del tracking
  return {
    executeEvent,
    trackingReady
  };
};

export default useTrackingQueue;
