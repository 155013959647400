/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { isDesktop: false };

const machineInformationSlice = createSlice({
  name: 'machineInformation',
  initialState,
  reducers: {
    setMachineInformation(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});
export default machineInformationSlice.reducer;
export const { setMachineInformation } = machineInformationSlice.actions;
