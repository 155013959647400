import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  landing: { landingInfo: {}, landingName: '' },
  gender: '',
  keywords: null,
  page: '',
  storeId: '',
  cpcode: {},
  loading: false
};

const ssrInfoSlice = createSlice({
  name: 'ssrInfo',
  initialState,
  reducers: {
    setSsrInfo(_state, action) {
      return { ..._state, ...action.payload };
    },
    setPageLoading(_state, action) {
      return { ..._state, loading: action.payload };
    }
  }
});

export const { setSsrInfo, setPageLoading } = ssrInfoSlice.actions;
export default ssrInfoSlice.reducer;
