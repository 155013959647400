'use client';

import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { useStore } from './initStore';

const StoreProvider = ({ children, initialReduxState = {} }) => {
  const store = useStore(initialReduxState);

  return <Provider store={store}>{children}</Provider>;
};
StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialReduxState: PropTypes.object
};

export default StoreProvider;
