import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    setBanners(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});

export const { setBanners } = bannerSlice.actions;
export default bannerSlice.reducer;
